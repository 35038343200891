import React from 'react';
import { Helmet } from 'react-helmet';

const HeaderExtra: React.FC = () => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default HeaderExtra;
