import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { useCalendlyModal } from '@/hooks/useModal';
import { DataContainsNode, useTransformNode } from '@/hooks/useTransformer';
import Layout from '../components/projects/Layout';

const PricingPlans = loadable(
  () => import('../components/projects/PricingPlan'),
);
const Actions = loadable(() => import('../components/projects/Actions'));

const Faq = loadable(() => import('../components/projects/Faq'));
const Flow = loadable(() => import('../components/projects/Flow'));
const HeroBanner = loadable(() => import('../components/projects/HeroBanner'));
const IntroducingCompany = loadable(
  () => import('../components/projects/IntroducingCompany'),
);
const News = loadable(() => import('../components/projects/News'));
const Outcome = loadable(() => import('../components/projects/Outcome'));
const Solution = loadable(() => import('../components/projects/Solution'));
const What = loadable(() => import('../components/projects/What'));
const WhyRicoh = loadable(() => import('../components/projects/WhyRicoh'));

export const pageQuery = graphql`
  query ProjectsIndexPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-index-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            showFlow
          }
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.ProjectsIndexPageQuery;
};

const IndexPage: React.FC<Props> = ({ data }) => {
  const { frontmatter } = useTransformNode(data as DataContainsNode);
  const showFlow = frontmatter?.showFlow ?? true;
  const [isOpen, { toggleModal }] = useCalendlyModal();
  return (
    <Layout>
      <HeroBanner
        updateCalendlyModalIsOpen={toggleModal}
        calendlyModalIsOpen={isOpen}
      />
      <What />
      <Outcome />
      <Solution />
      <IntroducingCompany />
      <Actions
        updateCalendlyModalIsOpen={toggleModal}
        calendlyModalIsOpen={isOpen}
      />
      <WhyRicoh />
      <PricingPlans updateCalendlyModalIsOpen={toggleModal} />
      {showFlow && <Flow />}
      <News />
      <Faq />
      <Actions
        updateCalendlyModalIsOpen={toggleModal}
        calendlyModalIsOpen={isOpen}
      />
    </Layout>
  );
};

export default IndexPage;
