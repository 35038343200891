import 'fontsource-roboto';
import 'fontsource-noto-sans-jp';
import { createGlobalStyle } from 'styled-components';

export const FontStyle = createGlobalStyle`
  body{
    margin: 0;
    font-family: Roboto, "Noto Sans JP", Arial, sans-serif;
  }
`;
