import React from 'react';
import { useCalendlyModal } from '@/hooks/useModal';
import useLangRedirect from '../../hooks/useLangRedirect';
import usePageContext from '../../hooks/usePageContext';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import WebpProvider from '../../providers/WebpProvider';
import SEO from '../SEO';
import CalendlyModal from './CalendlyModal';
import Chat from './Chat';
import { FontStyle } from './GlobalThemes';
import HeaderExtra from './HeaderExtra';

const TemplateWrapper: React.FC = ({ children }) => {
  useLangRedirect();
  const { title, canonical } = usePageContext();
  const [isOpen, { toggleModal }] = useCalendlyModal();

  return (
    <WebpProvider>
      <HeaderExtra />
      <FontStyle />
      <SEO title={title} canonical={canonical} product="projects" />
      <Header
        updateCalendlyModalIsOpen={toggleModal}
        calendlyModalIsOpen={isOpen}
      />
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { toggleModal })
          : child,
      )}
      <Footer />
      <CalendlyModal />
      <Chat />
    </WebpProvider>
  );
};

export default TemplateWrapper;
