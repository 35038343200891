import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';

declare global {
  interface Window {
    zESettings: unknown;
    $projectscrisp: unknown[];
    CRISP_WEBSITE_ID: string;
  }
}

function appendWidget(type: GatsbyTypes.MarkdownRemarkFrontmatter['widget']) {
  switch (type) {
    case 'zendesk': {
      window.zESettings = {
        webWidget: {
          launcher: {
            label: {
              '*': 'Chat',
            },
          },
          zIndex: 100, // Display behind cookie consent
        },
      };

      const script = document.createElement('script');
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=1e31d325-084e-4306-9c8e-239e813a5caa';
      script.async = true;
      script.id = 'ze-snippet';
      document.body.appendChild(script);
      break;
    }

    case 'crisp': {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = 'd907623a-0dde-48b9-a5d3-d82c0a60a9fb';

      const script = document.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      document.body.appendChild(script);

      window.$crisp.push(['config', 'container:index', [100]]); // Display behind cookie consent
      break;
    }

    default:
      // eslint-disable-next-line no-console
      console.error('Unknown chat widget type');
      break;
  }
}

const PROJECTS_CHAT_QUERY = graphql`
  query ProjectsChat {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-chat" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            widget
          }
        }
      }
    }
  }
`;

const Chat: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.ProjectsChatQuery>(
    PROJECTS_CHAT_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const widget = frontmatter?.widget;

  React.useEffect(() => {
    if (widget !== 'none') {
      setTimeout(() => appendWidget(widget), 3500);
    }
  }, [widget]);

  return null;
};

export default Chat;
