import React from 'react';
import styled from 'styled-components';
import { useCalendlyModal } from '@/hooks/useModal';

const BackgroundOverlay = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background-color: #323232;
  opacity: 0.5;
  &[data-popupopen='false'] {
    visibility: hidden;
  }
`;

const CalendlyModalWrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 100000;
  width: 600px;
  margin-top: -280px;
  margin-left: -300px;
  background-color: #fff;
  opacity: 100;
  &[data-popupopen='false'] {
    visibility: hidden;
    opacity: 0;
  }
  @media (max-width: 600px) {
    width: 500px;
    margin-top: -300px;
    margin-left: -250px;
  }
  @media (max-width: 500px) {
    width: 400px;
    margin-top: -250px;
    margin-left: -200px;
  }
  @media (max-width: 400px) {
    width: 320px;
    margin-top: -280px;
    margin-left: -160px;
  }
  @media (max-height: 700px) {
    margin-top: -210px;
  }
  @media (max-height: 620px) {
    margin-top: -160px;
  }
  @media (max-width: 350px) {
    width: 320px;
    margin-top: -180px;
    margin-left: -160px;
  }
`;

const CalendlyModalContent = styled.div``;

const ClosePopup = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 30px;
  padding: 2px 8px;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
  background-color: #c1c1c1;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 0.8;
    transition: opacity 0.5s ease;
  }
`;

const CalendlyModal: React.FC = () => {
  const [isOpen, { toggleModal }] = useCalendlyModal();
  React.useEffect(() => {
    setTimeout(() => {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://assets.calendly.com/assets/external/widget.js',
      );
      if (head) {
        head.appendChild(script);
      }
    }, 3000);
  });
  return (
    <>
      <CalendlyModalWrapper data-popupopen={isOpen} data-cy="calendlyProjects">
        <ClosePopup onClick={toggleModal}>X</ClosePopup>
        <CalendlyModalContent
          className="calendly-inline-widget"
          data-url="https://calendly.com/steve-1404/30min"
          style={{
            height: '60vh',
            maxHeight: '650px',
            minHeight: '350px',
            minWidth: '320px',
          }}
        />
      </CalendlyModalWrapper>
      <BackgroundOverlay
        onClick={toggleModal}
        data-popupopen={isOpen}
      ></BackgroundOverlay>
    </>
  );
};

export default CalendlyModal;
