import { useCallback } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import omit from 'lodash.omit';
import qs from 'qs';

export const useModal = (qsKey: string) => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isOpen = Object.keys(query).includes(qsKey);
  const openModal = useCallback(() => {
    navigate(
      '.' + qs.stringify({ ...query, [qsKey]: null }, { addQueryPrefix: true }),
      { replace: true },
    );
  }, [qsKey, query]);
  const closeModal = useCallback(() => {
    navigate('.' + qs.stringify(omit(query, qsKey), { addQueryPrefix: true }), {
      replace: true,
    });
  }, [qsKey, query]);
  const toggleModal = useCallback(() => {
    const openOrClose = isOpen ? closeModal : openModal;
    openOrClose();
  }, [isOpen, closeModal, openModal]);
  return [isOpen, { openModal, closeModal, toggleModal }] as const;
};

export const CALENDLY_MODAL_KEY = 'demo' as const;
export const useCalendlyModal = () => useModal(CALENDLY_MODAL_KEY);

export const PRODUCTS_MODAL_KEY = 'product' as const;
export const useProductsModal = () => useModal(PRODUCTS_MODAL_KEY);

export const MODAL_KEYS = [CALENDLY_MODAL_KEY, PRODUCTS_MODAL_KEY];
